.recharts-surface {
  margin-right: 40px;
}

.chart-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  padding-left: 0;
}

.segmented-control label {
  text-align: center !important;
  justify-content: center !important;
  font-size: 12pt;
  line-height: 1em;
  padding: 5px;
  -moz-transition-duration: 0.2s !important;
  -o-transition-duration: 0.2s !important;
  -webkit-transition-duration: 0.2s !important;
  transition-duration: 0.2s !important;
}

.segmented-control {
  height: 30px;
  width: 250px !important;
  margin-left: 10px !important;
  margin-right: auto !important;
}

.recharts-tooltip-wrapper {
  z-index: 1 !important;
}
