a[tooltip]::before {
  visibility: visible;
  opacity: 1;
}

nav.fab-container {
  z-index: 999;
}

nav.fab-container-sections {
  bottom: 5vh;
  right: 5vw;
  left: auto;
}

nav.fab-container-map {
  left: 5vw;
  right: auto;
  bottom: 5vh;
}

.clear-input {
  position: absolute;
  right: 3%;
  bottom: auto;
  top: 24%;
  z-index: 999;
}

.clear-input:hover {
  color: #93024e;
  cursor: pointer;
}

.leaflet-popup {
  max-height: 70vh;
  width: 70vw;
}

.leaflet-popup-content-wrapper {
  max-height: inherit;
  overflow: scroll;
  display: flex;
  justify-content: center;
}

.board-input-field {
  background: #fafafa;
  border-radius: 23px;
  height: 46px;
  padding-left: 16px;
}

@media only screen and (max-width: 992px) {
  .clear-input {
    right: 10%;
  }

  .leaflet-popup {
    width: 90vw;
  }

  .hide-on-mobile {
    visibility: hidden;
  }
}
