.card {
  transition: transform 0.2s;
  cursor: pointer;
}

.card-subtitle {
  color: #552356 !important;
}

.card:hover {
  transform: scale(1.1);
  z-index: 999;
}

.card-body {
  padding: 25px 15px 25px 15px !important;
}

.card-text {
  font-weight: normal;
  font-size: 11pt;
  color: #333;
}

.selectedSub {
  box-shadow: inset 0px 0px 1px 2px #552356;
}

.change-subscription-plan {
  cursor: pointer;
  font-weight: bold;
}

.change-subscription-plan:hover {
  text-decoration: underline;
}

.button {
  display: inline-block;
  margin: 20px auto;
  background: #93024e;
  color: #fefefe;
  font-size: 1.2em;
  padding: 1em;
  border-radius: 32px;
  text-align: center;
  position: relative;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  transition: border-radius ease-in-out 0.2s, width ease-in-out 0.2s;
  box-shadow: 0 5px 10px 2px rgba(51, 51, 51, 0.411);
}

.button:active {
  box-shadow: inset 0 5px 0 2px rgba(51, 51, 51, 0.411);
}

.button:disabled {
  opacity: 0.4;
  box-shadow: inset 0 0 0;
  cursor: not-allowed;
}

.button-success {
  background-color: #8bc34a;
}

.button:focus {
  outline: 0;
}

.button.animate {
  width: 64px !important;
  height: 64px;
  min-width: 0;
  border-radius: 50%;
  color: transparent;
}

.button.animate:after {
  position: absolute;
  content: "";
  width: 25px;
  height: 25px;
}

.button.animate.start:after {
  border: 4px solid #fefefe;
  border-radius: 50%;
  border-left-color: transparent;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation: spin ease-in infinite both;
  animation-name: spin;
  -webkit-animation-name: spin;
  transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  animation-duration: 30s;
  -webkit-animation-duration: 30s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.button.success:before {
  position: absolute;
  content: "";
  width: 25px;
  height: 12.5px;
  border: 4px solid #fefefe;
  border-right: 0;
  border-top: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  animation: success ease-in 0.15s forwards;
  -webkit-animation: success ease-in 0.15s forwards;
}

.button.error {
  position: relative;
  -webkit-animation: vibrate ease-in 0.5s forwards;
  animation: vibrate ease-in 0.5s forwards;
}

.button.error:before {
  color: #fff;
  position: absolute;
  content: "!";
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  animation: error ease-in 0.5s forwards;
  -webkit-animation: error ease-in 0.5s forwards;
}

/* Animations */

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(360deg) scale(1);
  }
  100% {
    transform: translate(-50%, -50%) rotate(159000deg) scale(1);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg) scale(1);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(159000deg) scale(1);
  }
}

@keyframes success {
  from {
    transform: translate(-50%, -50%) rotate(0) scale(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(-45deg) scale(1);
    background-color: #8bc34a;
  }
}

@-webkit-keyframes success {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(0) scale(0);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg) scale(1);
    background-color: #8bc34a;
  }
}

@keyframes error {
  from {
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    background-color: #f44336;
  }
}

@-webkit-keyframes error {
  from {
    -webkit-transform: translate(-50%, -50%) scale(0);
  }
  to {
    -webkit-transform: translate(-50%, -50%) scale(1);
    background-color: #f44336;
  }
}

@keyframes vibrate {
  0%,
  30%,
  60%,
  85%,
  100% {
    left: 0;
    background-color: #f44336;
  }
  10%,
  40%,
  90%,
  70% {
    left: -2px;
    background-color: #f44336;
  }
  20%,
  50%,
  80%,
  95% {
    left: 2px;
    background-color: #f44336;
  }
}
