@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dropdown-item:active {
  color: #ffffff !important;
  background-color: #7a0c4b;
}

.wine-section {
  transition: all 200ms ease-out;
  overflow: hidden;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  z-index: 0 !important;
}

.add-wine-form {
  overflow-y: hidden;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 65px;
  width: auto;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 350;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 900 !important;
  max-height: 50vh !important;
  overflow: scroll;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.plus-minus-input {
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}

.plus-minus-input .input-group-button .circle {
  border-radius: 50%;
  padding: 0.25em 0.5em;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}

.minus {
  background-color: #d9534f !important;
}

.dropdown-custom-anchor {
  font-size: 11pt;
}

.unit {
  position: absolute;
  display: block;
  right: 8px;
  top: 30px;
  z-index: 9;
  color: #fff;
  background-color: #93024e;
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 3px;
}

.required-form-field {
  font-weight: 900 !important;
  font-size: larger;
  margin-left: 10px;
}

.align-middle-custom {
  vertical-align: middle !important;
}

/* Magazzino */

.wine-card {
  background-color: #ffffff00;
  border-radius: 3px;
  page-break-inside: avoid;
}

.region {
  font-weight: 500;
}

.wine-title {
  page-break-after: avoid;
}

.wine-region {
  page-break-after: avoid;
  page-break-inside: avoid;
}

.wine-region-section {
  transition: all 200ms ease-out;
}

input[type="radio"],
input[type="radio"]:focus,
input[type="radio"]:active,
input[type="radio"]:visited,
input[type="radio"]:checked {
  border-color: rgba(255, 255, 255, 0) !important;
  outline-color: rgba(255, 255, 255, 0) !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0) !important;
  z-index: 9;
}

.form-check-label:checked {
  color: #333 !important;
}

/* WineCard */

.primary-wine-row {
  font-weight: 500;
  color: #93024e !important;
}

.primary-wine-row:hover {
  color: #7a0c4b !important;
}

.fs-1 {
  font-size: 1rem !important;
}

.fs-11 {
  font-size: 1.1rem !important;
}

.fs-12 {
  font-size: 1.2rem !important;
}

.fs-13 {
  font-size: 1.3rem !important;
}

.primary-wine-row:hover {
  color: rgb(170, 80, 119);
}

.secondary-wine-row {
  font-weight: 400;
}

.volume {
  font-weight: 450;
}

.material-input {
  font-size: 18px;
  padding: 0px 5px 0px 5px;
  display: block;
  border: none;
  border-bottom: 1px solid #757575;
  width: 60%;
  height: 32px;
  background-color: rgba(255, 255, 255, 0);
}

.material-input:focus {
  outline: none;
  border-color: rgba(152, 64, 102, 0) !important;
  outline-color: rgba(152, 64, 102, 0) !important;
  box-shadow: 0 0 0 0 rgba(152, 64, 102, 0) !important;
}

.ricarico-prezzo {
  position: absolute;
  top: 50%;
  right: 10%;
  left: auto;
  visibility: visible;
  font-size: 8pt;
  color: #fff;
  width: 28px;
  height: 28px;
  max-width: 80px;
  padding: 0px 8px 0px 8px;
  background-color: #888888;
  border-radius: 30px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  padding-top: 1px;
}

.ricarico-prezzo i {
  font-size: 5pt;
  padding: 0;
  margin: 0;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  max-height: 448px;
}

.availability-change {
  font-size: 12pt;
  color: #fff;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #93024e;
  border-radius: 30px;
  cursor: pointer;
}

.price-column {
  width: 80%;
}

.availability-column {
  width: 90%;
}

@media screen and (max-width: 991px) {
  .align-middle-custom {
    vertical-align: unset !important;
  }

  .fa-wine-bottle {
    height: 0.7em !important;
  }

  .fa-euro-sign {
    height: 0.7em !important;
  }

  .container {
    width: 100% !important;
  }

  .availability-esaurito {
    left: -5%;
  }

  .availability-esaurimento {
    left: -5%;
  }

  .react-autosuggest__suggestions-container--open {
    width: 75vw;
  }
}
