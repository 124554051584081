@font-face {
  font-family: "Audrey";
  src: url("./fonts/Audrey-Medium.otf") format("otf");
}

:root {
  --loader_glass: #0a81963b;
}

body {
  font-weight: 440;
  background-color: #f2eaed !important;
  color: #333;
  height: calc(100% - 1px);
  overflow-x: hidden;
  overflow-x: -moz-hidden-unscrollable;
}

#root {
  overflow-x: hidden;
  overflow-x: -moz-hidden-unscrollable;
}

input[type="radio"] {
  transform: scale(1.5);
}

strong {
  font-weight: 800;
}

.form-check-input:checked {
  color: #7a0c4b;
}

.form-control {
  outline: none;
  border: 0.5px solid #55235609;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #7a0c4b;
}

p {
  margin: auto;
}

label:after {
  z-index: 1 !important;
}

/* 
 *  Loader
 */

.loader {
  transform: scale(1.2);
}

.wineglass {
  height: 36px;
  position: relative;
  background-attachment: fixed;
}

.wineglass:after {
  content: "";
  position: absolute;
  top: 47px;
  left: 5px;
  width: 20px;
  height: 5px;
  background: var(--loader_glass);
  box-shadow: 0 0 1px var(--loader_glass);
}

.loader__top {
  background: var(--loader_glass);
  width: 30px;
  height: 36px;
  border-radius: 0 0 36px 36px;
  box-shadow: 0 0 1px var(--loader_glass);
}

.loader__top:before {
  content: "";
  position: absolute;
  left: 4px;
  bottom: 4px;
  width: 22px;
  height: 28px;
  border-radius: 50%;
  background: linear-gradient(transparent 50%, #7a0c4b 50%);
}

.loader__left .loader__top:before {
  animation: rotate2 1.4s ease-in infinite;
}

.loader__right .loader__top:before {
  animation: rotate1 1.4s ease-in infinite;
}

.loader__top:after {
  content: "";
  position: absolute;
  top: 35px;
  left: 12px;
  width: 6px;
  height: 13px;
  background: var(--loader_glass);
  box-shadow: 0 0 1px var(--loader_glass);
}

.loader__left {
  display: inline-block;
  margin-right: 10px;
  animation: rotate1 1.4s cubic-bezier(0.39, 1.52, 0.46, 0.92) infinite;
}

.loader__right {
  display: inline-block;
  animation: rotate2 1.4s cubic-bezier(0.39, 1.52, 0.46, 0.92) infinite;
}

@keyframes rotate1 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(22deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-22deg);
  }
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 0 0 0; /* Height of navbar */
  z-index: 999; /* Sopra la navbar */
  font-size: 0.9rem;
}

.sidebar-sticky {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  background: url("./img/Pattern_Sidebar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.sidebar-logo {
  padding: 0 0 0;
  height: 48px;
}

.sidebar-footer {
  position: relative;
  margin: auto auto 20px;
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.692);
}

.footer-link {
  color: inherit;
}

.footer-link:hover {
  color: #fff;
  text-decoration: underline;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: relative;
  }
}

.profile-picture-sidebar {
  padding: 0 !important;
}

.profile-picture-sidebar.active {
  background: none !important;
}

.sidebar .nav-item {
  padding: 5px 0;
}

.sidebar .nav-link {
  font-size: 1rem;
  font-weight: 450;
  padding: 12px 40px;
  color: #fff;
}

.sidebar .nav-link:hover {
  background: linear-gradient(to left, #cc026491, #cc026400);
}

.sidebar .nav-item .active {
  color: #fff;
  font-weight: 800;
  background: linear-gradient(to left, #cc026491, #cc026400);
}

.mobile-only {
  visibility: hidden;
}

.user-name {
  text-transform: uppercase;
}

/*
 * Content
 */

[role="main"] {
  padding-top: 48px; /* Space for fixed navbar */
}

@media only screen and (max-width: 992px) {
  .search-bar {
    width: 60% !important;
  }

  .ml-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .navbar-brand {
    display: none;
  }

  .navbar {
    margin-top: env(safe-area-inset-top, 0);
  }

  .sidebar {
    box-shadow: none;
    display: none !important;
  }

  .container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .px-4 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .search-icon {
    margin-right: 2rem !important;
  }

  .mobile-only {
    visibility: visible;
  }

  .centered-logo {
    width: 100% !important;
    margin-right: auto;
  }

  .trailing-buttons {
    right: 25px !important;
  }
}

/*
 * Navbar
 */

.navbar {
  background-color: #fff !important;
  box-shadow: 0 0 12px 10px rgba(0, 0, 0, 0.048) !important;
  min-height: 55px;
  z-index: 2;
}

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.navbar .nav-link {
  color: #552356 !important;
}

.centered-logo {
  width: 83%;
  height: 55px;
  margin-left: 0 !important;
  margin-right: 2px !important;
}

.trailing-buttons {
  position: absolute;
  right: 40px;
}

.search-icon {
  margin-right: 50px;
  cursor: pointer;
}

.form-control-dark {
  color: #fff;
  background-color: #ebdfed7a;
  border-radius: 26px !important;
}

.form-control-dark::placeholder {
  color: rgba(51, 51, 51, 0.425);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

/*
 * Custom
 */

input {
  font-weight: 600 !important;
}

::-webkit-input-placeholder {
  /* Edge */
  font-weight: 400 !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: 400 !important;
}

::placeholder {
  font-weight: 400 !important;
}

textarea:focus,
textarea:active,
textarea:visited {
  border-color: #ffffff00;
  outline-color: #ffffff00;
  box-shadow: none;
}

select:focus,
select:active,
select:visited {
  border-color: rgba(152, 64, 102, 0.349) !important;
  outline-color: rgba(152, 64, 102, 0.349) !important;
  box-shadow: 0 0 0 0.2rem rgba(152, 64, 102, 0.349) !important;
}

/*
 * Stripe
 */

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100vh;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-right: auto !important;
  margin-left: auto !important;

  box-shadow: 0 1px 3px 0 #fff;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #55235659;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
