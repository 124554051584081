.form-signup {
  width: 100%;
  max-width: 720px;
  padding: 15px;
  margin: auto;
}

.btn-outline-secondary {
  border: none;
  height: auto;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-radius: 5px !important;
}
